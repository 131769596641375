<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-alert
      v-if="formErrors"
      type="error"
      class="ma-0"
    >
      <strong v-text="formErrors.split(':')[0]" />
      <p v-text="formErrors.split(':')[1]" />
    </v-alert>
    <v-card
      v-if="object"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Object #${object.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="saveObject"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <v-tabs
        v-model="tab"
        class="px-6 py-4"
      >
        <v-tab
          v-for="item in tabs"
          :key="item.name"
        >
          {{ item.name }}
        </v-tab>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-2"
        >
          <v-tab-item
            v-for="item in tabs"
            :key="item.name"
          >
            <component
              :is="item.component"
              :object="object"
              :object-data="object"
              @updateObject="handleUpdateObject"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-divider />
      <div class="px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
            @click="saveObject"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
      <v-snackbar
        v-model="showSuccessMessage"
        color="success"
        timeout="3500"
      >
        Object saved successfully
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
  import axios from '@/plugins/api'

  export default {
    components: {
      ObjectTab: () => import('@/components/object-details/ObjectTab'),
      DetailsTab: () => import('@/components/object-details/DetailsTab'),
      ImagesTab: () => import('@/components/object-details/ImagesTab'),
      PricesTab: () => import('@/components/object-details/PricesTab'),
      InsuranceTab: () => import('@/components/object-details/InsuranceTab'),
      ObjectRatingsTab: () => import('@/components/object-details/ObjectRatingsTab'),
      ObjectNotesTab: () => import('@/components/object-details/ObjectNotesTab'),
      MetadataTab: () => import('@/components/object-details/MetadataTab'),
    },

    data () {
      return {
        objects: [
          {
            id: 6500,
            ownerId: 10650,
            title: 'Drohne',
            objectStatus: 'Active',
            objectFlag: 'Edited',
            objectType: 'Default',
            created: '2020 Jun 11, 13:54',
            objectCountAvailable: '5 / 4',
            fixedVariablePrice: '21.00 / 7.00',
            ratingCountAverage: '4 / 3.25',
            rentalRequests: 3,
            mainKeyword: 'Drohne',
            user: {
              address: {},
            },
          },
        ],
        object: null,
        tabs: [
          { name: 'Object', component: 'ObjectTab' },
          { name: 'Details', component: 'DetailsTab' },
          { name: 'Images', component: 'ImagesTab' },
          { name: 'Prices', component: 'PricesTab' },
          { name: 'Insurance', component: 'InsuranceTab' },
          { name: 'Object Ratings', component: 'ObjectRatingsTab' },
          { name: 'Object Notes', component: 'ObjectNotesTab' },
          { name: 'Metadata', component: 'MetadataTab' },
        ],
        tab: null,
        formErrors: null,
        showSuccessMessage: false,
      }
    },

    created () {
      if (this.$route.query.activeTab) {
        this.tab = this.tabs.findIndex(el => el.name.toLowerCase() === this.$route.query.activeTab.toLowerCase())
      }
      if (this.objects.length && this.objects.find(el => el.id == this.$route.params.id)) {
        this.object = this.objects.find(el => el.id == this.$route.params.id)
      }
      this.loadObject()
    },
    methods: {
      loadObject () {
        this.object = this.objects.find(el => el.id == this.$route.params.id)
        axios.get(`/admin/products/${this.$route.params.id}`)
          .then(response => {
            this.object = response.data
          })
      },
      saveObject () {
        const obj = this.object

        const formData = new FormData()

        // Append properties to formData
        formData.append('id', obj.id)
        formData.append('title', obj.title)
        formData.append('description', obj.description)
        formData.append('place_id', obj.place_id)

        for (const key in obj.price) {
          formData.append(`price[${key}]`, obj.price[key])
        }

        formData.append('insuranceYear', Number(obj.insuranceYear))
        formData.append('insurancePrice', Number(obj.insurancePrice))
        formData.append('status', obj.status)
        formData.append('flag', obj.flag)

        for (const key in obj.type) {
          formData.append(`type[${key}]`, obj.type[key])
        }

        // Check if photos property exists
        if (obj.photos) {
          // If obj.photos is an array of File objects
          for (let i = 0; i < obj.photos.length; i++) {
            formData.append('photos[]', obj.photos[i])
          }
        }
        if (obj.user) {
          for (const key in obj.user) {
            if (key === 'address') {
              for (const prop in obj.user.address) {
                formData.append(`user[address][${prop}]`, obj.user.address[prop])
              }
            } else {
              formData.append(`user[${key}]`, obj.user[key])
            }
          }
        }
        // obj.media is an array of objects
        if (obj.media) {
          for (let i = 0; i < obj.media.length; i++) {
            for (const key in obj.media[i]) {
              // obj.media[i].images is an object with keys: medium, small, thumb
              if (key === 'images') {
                for (const imageKey in obj.media[i].images) {
                  formData.append(`media[${i}][images][${imageKey}]`, obj.media[i].images[imageKey])
                }
                continue
              }
              formData.append(`media[${i}][${key}]`, obj.media[i][key])
            }
          }
        }

        if (obj.keywords) {
          for (const key in obj.keywords) {
            if (key === 'categories') {
              formData.append('categories', obj.keywords[key])
            } else {
              formData.append(`keywords[${key}]`, obj.keywords[key])
            }
          }
        }

        const _this = this
        axios.post('/admin/products/' + obj.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(res => {
            _this.formErrors = null
            if (res.status === 202) {
              _this.showSuccessMessage = true
            }
          }).catch(err => {
            _this.formErrors = err.response.data.message
          })
      },
      handleUpdateObject (object) {
        this.object = object
      },
    },
  }
</script>

<style lang="scss">

</style>
